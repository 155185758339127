<template>
  <div>
    <v-card-text>
      <v-row v-if="!isSchoolUser">
        <v-card-title>
          <v-col
            cols="12"
            sm="12"
            :md="isLGAUser ? 4 : 3"
            class="pa-0"
            v-if="canSeeRegion"
          >
            <v-autocomplete
              :label="'select Region'"
              v-model="region"
              :items="locations"
              :item-text="'name'"
              @change="fetchLGAs($event), setSelected($event)"
              prepend-icon="mdi-magnify"
              return-object
              required
              flat
              slot-scope
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            :md="isLGAUser ? 4 : 3"
            class="pa-0"
            v-if="canSeeLGA"
          >
            <v-autocomplete
              :label="'select LGA'"
              v-model="lga"
              :items="canLoadLGA ? lgas : locations"
              :item-text="'name'"
              prepend-icon="mdi-magnify"
              return-object
              required
              @change="
                schoolType();
                setSelected($event);
              "
              flat
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" :md="isLGAUser ? 4 : 3" class="pa-0">
            <v-autocomplete
              :label="'select School Type'"
              v-model="type"
              :items="schoolTypes"
              :item-text="'name'"
              @change="fetchFacilities($event), setSelected($event)"
              prepend-icon="mdi-magnify"
              return-object
              required
              flat
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" :md="isLGAUser ? 4 : 3" class="pa-0">
            <v-autocomplete
              :label="'Select Schools'"
              v-model="school"
              :items="schools"
              :item-text="'name'"
              return-object
              required
              class
              flat
              prepend-icon="mdi-magnify"
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
              @change="setSelected($event)"
            ></v-autocomplete>
          </v-col>
        </v-card-title>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import {
  get as fetchLocation,
  schools as fetchSchools,
  getByChildren,
} from "../locations/services";

export default {
  name: "FacilityLoader",
  props: ["selected", "setActive"],

  data() {
    return {
      items: [],
      region: null,
      lga: null,
      school: null,
      type: null,
      lgas: [],
      schools: [],
      schoolTypes: [],
      formData: {},
    };
  },

  methods: {
    fetchLGAs(data) {
      getByChildren({
        "location.Id": data.id,
      }).then((response) => {
        this.lgas = response.data[0]
          ? response.data[0].data
              .map((lga) => ({
                ...lga,
              }))
              .sort(function(a, b) {
                if (a > b) return 1;
                return -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },

    fetchFacilities(data) {
      fetchSchools({
        "lga.Id": this.lga ? this.lga.id : this.user.location.id,
        "schoolType.Id": data.id,
      }).then((response) => {
        this.schools = response.data[0]
          ? response.data[0].data
              .map((school) => ({
                ...school,
              }))
              .sort(function(a, b) {
                if (a > b) return 1;
                return -1;
              })
              .map((item, index) => ({
                ...item,
                name: item.code ? item.code + " - " + item.name : item.name,
                index: ++index,
              }))
          : [];
      });
    },

    init(params) {
      getByChildren({
        "location.Id": this.user.location ? this.user.location.id : null,
        ...params,
      }).then((response) => {
        this.items = response.data[0]
          ? response.data[0].data
              .map((lga) => ({
                ...lga,
              }))
              .sort(function(a, b) {
                if (a > b) return 1;
                return -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },

    levelMappings(data) {
      switch (data.level) {
        case 1:
          this.formData.countryId = data.id;
          break;
        case 2:
          this.formData.regionId = data.id;
          break;
        case 3:
          this.formData.lgaId = data.id;
          break;
        case 4:
          this.formData.wardId = data.id;
          break;
        case 5:
          this.formData.schoolId = data.id;
          break;
      }
    },
    schoolType() {
      this.$mixins.fetchByCategory("SCHOOL_TYPE").then((response) => {
        this.schoolTypes = response;
      });
    },

    setSelected(data) {
      this.setActive(data);
    },
  },

  mounted() {
    this.init();

    //load this if use is at lga
    if (this.user && this.user.location && this.user.location.level.id === 3) {
      this.schoolType();
    }
  },

  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
    locations() {
      return this.items.map((item) => ({
        id: item.id,
        type: item.school_type ? item.school_type.name : "",
        name: item.name,
        level: item.level.name,
        ...item,
      }));
      // .map((item) => ({
      //   ...item,
      //   name:
      //     item.name + " " + "(" + item.type + "  " + item.level + ")" + " ",
      // }));
    },

    canSeeRegion() {
      if (
        this.user.location &&
        this.user.location.level &&
        this.user.location.level.id == 1
      ) {
        return true;
      } else {
        return false;
      }
    },

    canSeeLGA() {
      if (
        (this.user.location &&
          this.user.location.level &&
          this.user.location.level.id == 1) ||
        (this.user.location &&
          this.user.location.level &&
          this.user.location.level.id == 2)
      ) {
        return true;
      } else {
        return false;
      }
    },

    canLoadLGA() {
      if (
        this.user.location &&
        this.user.location.level &&
        this.user.location.level.id == 1
      ) {
        return true;
      } else {
        return false;
      }
    },

    isLGAUser() {
      if (
        this.user &&
        this.user.location &&
        this.user.location.level.id === 3
      ) {
        return true;
      } else {
        return false;
      }
    },

    isSchoolUser() {
      if (
        this.user &&
        this.user.location &&
        this.user.location.level.id === 5
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    //on change LGA then reset schoolTypes and  and schools
    lga() {
      this.types = [];

      this.schoolTypes = [];
    },
  },
};
</script>

<style scoped lang="scss"></style>
